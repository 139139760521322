import React, { useEffect, useState } from 'react'
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import { Card, Text, Button, Box, Heading } from 'theme-ui'
import { getStaticPageSeoDetails } from '@utils-blog'
import { Link } from 'gatsby'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'
import HeroComponent from '../../../../themes/gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import Apis from '../apis/francis'

const CustodiansOfTheTradition = props => {
  const [list, setList] = useState([])
  useEffect(() => {
    getList()
  }, [])

  const styles = {
    guide: {
      mb: 2,
      ml: 2
    },

    label: {
      marginBottom: `1rem`,
      fontSize: `1.25rem`
    },
    image: {
      width: '100%',
      height: '200px',
      objectFit: 'cover'
    },
    description: {
      display: '-webkit-box',
      '-webkit-line-clamp': '6',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden'
    },
    gridCol: {
      display: 'grid',
      gridTemplateColumns: '33% 33% 33%',
      gap: '20px',
      '@media screen and (max-width: 600px)': {
        gridTemplateColumns: 'auto'
      },
      '@media screen and (min-width: 601px) and (max-width: 1024px)': {
        gridTemplateColumns: 'auto auto'
      },
      '>div': {
        bg: `omegaLighter`,
        borderRadius: `1rem`,
        overflow: `hidden`,
        '>div': {
          px: 20,
          pb: 20,
          pt: 10
        }
      }
    }
  }

  const getList = async () => {
    const response = await Apis.getCategoryData('Custodians of the Tradition')
    response.data.data.forEach(element => {
      let splitLabel = ''
        splitLabel = element.label.replace(/[^\w\s-]/gi, '').toLowerCase()
        splitLabel = splitLabel.replace(/ /g, '_')
      element.identifier = encodeURIComponent(element.identifier).replace(
        /[!'()*]/g,
        function (c) {
          return '%' + c.charCodeAt(0).toString(16)
        }
      )
      element.url = `${process.env.GATSBY_REDIRECT_URL}${
        element.identifier
      }/${splitLabel}/${element.date.toLowerCase()}`
    })
    setList(response.data.data)
  }

  let seoData = getStaticPageSeoDetails('shared.custodians-series-page')

  const custodiansSeoData = {
    ...seoData,
    Custodians: {
      ...seoData?.Custodians,
      keywords: seoData?.Custodians?.keywords?.split('\n')
    }
  }
  return (
    <Layout {...props}>
      <Seo
        title={custodiansSeoData?.Custodians?.metaTitle}
        description={custodiansSeoData?.Custodians?.metaDescription}
        keywords={custodiansSeoData?.Custodians?.keywords}
        thumbnail={custodiansSeoData?.Custodians?.metaImage}
      />
      <Hero full>
        <Hero sx={{ padding: `50px 0` }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'CustodiansOfTheTradition'}
            {...props}
            callFrom='Custodians Of The Tradition'
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <Box sx={styles.gridCol}>
            {list.map((item, index) => {
              return (
                <>
                  <Box variant='paper'>
                    <img
                      src={item.thumbnail.imageUrl.replace(
                        'full/100',
                        'full/300'
                      )}
                      alt={item.label}
                      style={styles.image}
                    />
                    <div style={styles.section}>
                      <Heading variant={['title']}>
                        <Text variant={['card-title']} style={styles.label}>
                          {item.label}
                        </Text>
                      </Heading>
                      <Text variant='p' style={styles.description}>
                        {item.description}
                      </Text>
                      <Button
                        variant='primary'
                        as={Link}
                        target='_blank'
                        to={item.url}
                      >
                        Read More
                      </Button>
                    </div>
                  </Box>
                </>
              )
            })}
          </Box>
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main></Main>
      </Stack>
      <Divider />
    </Layout>
  )
}
export default CustodiansOfTheTradition
